.career-card{
    display: inline-block;
    width: calc(100% - 15% - 10px);
    text-align: right;
    right: 0;
}

.career-card h2{
    font-family: Neutro-ExtraBold;
    display: inline-block;
    vertical-align: middle;
    margin-right: 20%;

}

.timeline-container{
    vertical-align: middle;
    height: 30vh;
    text-align: left;
    vertical-align: middle;
}

.timeline-line{
    display: inline-block;
    height: 100%;
    width: 10px;

    vertical-align: middle;


}

.timeline-stop{
    width: 40px;
    height: 40px;
    background-color: rgb(221, 77, 21);
    /* top: calc(50% - 20px);
    position: relative; */
    /* translate square 50% to left:  */
    transform: translateX(-15px) rotate(45deg);


}

.timeline-stop-container{
    top: calc(50% - 20px);
    position: relative;

}

.timeline-label{
    display: inline-block;
    width: 15%;
     font-family: Neutro-ExtraBold;
     vertical-align: middle;
}

.timeline-card-container{
    display: inline-block;
    width: 95%;
    position: relative;
    /* margin-left: 20%; */
    vertical-align: middle;
    text-align: right;
       

}

.timeline-card{
    display: inline-block;
    border-radius: 20px;
    text-align: left;
    width: 100%;
    vertical-align: middle;
    margin-block: 15px;
}

.timeline-card h1{
    font-family: Neutro-ExtraBold;
    font-size: 2em;
    text-align: left;
    margin-inline: 20px;
    margin-bottom: 0px;
}

.timeline-card p{
    font-family: Victormono-Bold;
    font-size: 1em;
    text-align: left;
    margin-inline: 20px;
}

.career-card h3{
    font-family: Neutro-ExtraBold;
    font-size: 1em;
    text-align: left;
    margin-inline: 20px;
    margin-block: 0;
}

@media (max-width: 1200px) { 
    .career-card h2{
    font-family: Neutro-ExtraBold;
    display: inline-block;
    vertical-align: middle;
    font-size: 1em;
}
  
}

@media (max-width: 1200px) { 
    .career-card{
        text-align: center;
        display: inline-block;
        width: 100%;
    }
    .timeline-label{
        display: none;
    }
    .timeline-line{
        display: none;
    }
  
    .timeline-card-container{
    display: inline-block;
    width: 100%;
    text-align: right;
}
.timeline-container{
    height: auto;
}

}