@font-face {
    font-family: Akira-Expanded;
    src: url(fonts/Akira-Expanded.otf);
}

@font-face {
    font-family: Neutro-ExtraBold;
    src: url(fonts/Neutro-ExtraBold.otf);
}

@font-face{
    font-family: SF-Pro-Text-Semibold;
    src: url(fonts/SF-Pro-Text-Semibold.otf);
}

.flair-text{
    font-family: neutro-extrabold;
    z-index: 1;
   
}

.flair-text::before{
    content: attr(data-content);; 
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    transform: translateY(-70%);
    color: transparent;
    -webkit-text-stroke: 1px white;
    /* animation: floatTextSideDown 1.5s linear forwards; */
}



.flair-text::after{
    content: attr(data-content);; 
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    transform: translateY(70%);
    color: transparent;
    -webkit-text-stroke: 1px white;
    /* animation: floatTextSideUp 1.5s linear forwards; */
}
.flair-text.animate-before::before {
  animation: floatTextSideUp 0.6s ease-in-out forwards;
}

.flair-text.animate-after::after {
  animation: floatTextSideDown 0.6s ease-in-out forwards;
}


@keyframes floatTextSideDown {
  0% {
    transform: translateY(-70%);
   

  }
  100% {
    transform: translateY(-40%);
   
  }
}


@keyframes floatTextSideUp {
  0% {
    transform: translateY(70%);

  }
  100% {
    transform: translateY(40%);
 
  }
}

.flair-text-container{
    text-align: center;
    position: relative;
    z-index: 1;
    margin-top: 7vh;
    margin-bottom: 7vh;
    
}

.text-size-large{
    font-size: 4.5rem;
}

h1{
    font-family: neutro-extrabold;
    font-size: 4.5rem;
    color: white;
    text-align: center;
    /* create letter-spacing:; */
    /* uppercase; */
    z-index: 1;
}

@media (max-width: 1000px) { 
  .text-size-large{
    font-size: 3rem;
}
h1{
  font-size: 3rem;

}
  
}

@media (max-width: 768px) { 
  .text-size-large{
    font-size: 2rem;
}
h1{
  font-size: 2rem;

}
  
}



h3{
    font-family: Victormono-Bold;
    font-size: 1.5rem;
    color: white;
    text-align: center;
    /* create letter-spacing:; */
    /* uppercase; */
    z-index: 1;
}

p{
    font-family: Victormono-Bold;
    font-size: 1.2rem;
    color: white;
    text-align: center;
    color: rgb(192, 179, 179);
    /* create letter-spacing:; */
    /* uppercase; */
    z-index: 1;
}

.subtext{
    font-family: Victormono-Bold;
    font-size: 0.8rem;
    text-align: center;
    /* create letter-spacing:; */
    /* uppercase; */
    z-index: 1;
}

.text-outline{
  color: transparent;
  -webkit-text-stroke: 1px white;
}